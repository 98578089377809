@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@500&display=swap);
body {
  font-family: 'Libre Franklin', sans-serif;
}

.navbar {
  background-color: white;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
}

.heroImage {
  height: 28rem;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
  /* text-decoration: none; */
}

.heroIcons {
  color: #01014d;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 1px 2px 8px rgba(1, 1, 77, 0.3);
  list-style-type: none;
}

.heroCard {
  border: none;
}

.policiesCards {
  background-color: rgb(1, 1, 77);
  border: none;
  color: white;
}

#services, .note {
  background-color: rgb(1, 1, 77);
}

.footer {
  background-color: rgb(1, 1, 77);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 760px) {
  .contDetails {
    text-align: center;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
